<template>
  <v-container fluid>
    <v-toolbar color="secondary" flat dense>
      <v-toolbar-title>Route User Assignments</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined small text @click="goBack">
        <v-icon left dark>mdi-skip-backward</v-icon>Back
      </v-btn>
    </v-toolbar>
    <!-- editor -->
    <editor
      :initial="itemsData"
      @data="save"
      :RemarksDialog.sync="RemarksDialog"
    ></editor>
    <snackbar ref="snackbar"></snackbar>
    <!-- end -->
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
export default {
  components: {
    Editor,
  },
  data: () => ({
    itemsData: {},
    editMode: false,
    Dialog: false,
    DocApproval: {},
    docData: {},
    RemarksDialog: false,
    saved: false,
  }),
  methods: {
    save(data) {
      console.log("SENDING....");
      console.log(data);
      this.docData = data;
      let URL = "/route_assignment/approval_templates";
      if (this.docData.addDraft === true) {
        URL = "/route_assignments";
      }
      const ENDPOINT = URL;
      const self = this;
      this.$store
        .dispatch("post", { data, url: ENDPOINT })
        .then((res) => {
          self.$store.commit("loader", false);
          if (res.ResultCode == 1201 && res.ResponseData !== null) {
            self.RemarksDialog = true;
          } else {
            this.docData.addDraft = true;
            if (!this.saved) {
              this.saved = true;
              this.save(data);
            }
          }
        })
        .catch((err) => {
          self.$store.commit("loader", false);
          self.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },

    goBack() {
      this.$router.go(-1);
    },
  },
  created() {},
};
</script>
